body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.MuiToolbar-regular {
    min-height: 64px; /* Adjust based on header height */
}

.MuiDrawer-paper {
    top: 64px; /* Adjust based on header height */
}
